var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative h-full" }, [
    _c(
      "div",
      { staticClass: "flex h-full flex-col items-center justify-center" },
      [
        _c(
          "div",
          { staticClass: "w-full max-w-4xl px-4" },
          [
            _c("card", { staticClass: "mx-auto py-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mx-auto flex h-full max-w-2xl flex-col justify-center",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-4 flex flex-wrap justify-start px-8" },
                    [
                      _c("div", { staticClass: "w-full pb-12" }),
                      _c("img", {
                        staticClass: "mx-auto h-12 w-auto",
                        attrs: {
                          alt: "FirstVet logo",
                          src: require("@/assets/svg/firstvet_logo.svg"),
                        },
                      }),
                    ]
                  ),
                  _c("p", { staticClass: "text-center text-red-600" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$i18n.tc("call_triage.auth.only_for_sos_users")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4 w-full",
                      attrs: {
                        color: "primary",
                        to: _vm.firstvetAuthUrl,
                        href: _vm.firstvetAuthUrl,
                        "data-testid": "login-button",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.tc("call_triage.auth.login_signup")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }