var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-modal",
        { attrs: { size: "sm", show: _vm.showVerificationModal } },
        [
          _c("h2", { staticClass: "mb-2 text-lg" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$i18n.tc("call_triage.auth.revalidate_email")) +
                " "
            ),
          ]),
          _c("p", { staticClass: "py-8" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$i18n.tc("call_triage.auth.revalidate_email_text")) +
                " "
            ),
          ]),
          _c(
            "base-button",
            {
              attrs: { type: "button", color: "primary" },
              on: { click: _vm.startEmailVerification },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$i18n.tc("call_triage.auth.validate")) + " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }