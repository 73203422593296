var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative h-full" }, [
    _c(
      "div",
      { staticClass: "flex h-full flex-col items-center justify-center" },
      [
        _c(
          "div",
          { staticClass: "w-full max-w-4xl px-4" },
          [
            _c("card", { staticClass: "mx-auto py-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mx-auto flex h-full max-w-2xl flex-col justify-center",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-4 flex flex-wrap justify-start px-8" },
                    [
                      _c("div", { staticClass: "w-full pb-12" }),
                      _c("img", {
                        staticClass: "mx-auto h-12 w-auto",
                        attrs: {
                          alt: "FirstVet logo",
                          src: require("@/assets/svg/firstvet_logo.svg"),
                        },
                      }),
                    ]
                  ),
                  _vm.currentUiState === _vm.UI_STATES.idle ||
                  _vm.currentUiState === _vm.UI_STATES.error
                    ? _c("div", [
                        _vm.currentUiState === _vm.UI_STATES.error
                          ? _c("div", { staticClass: "text-red-600" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$i18n.tc(
                                      "call_triage.auth.could_not_validate_email"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "flex flex-row" },
                          [
                            _c("base-input", {
                              staticClass: "w-full",
                              attrs: { type: "text", placeholder: "Enter OTP" },
                              model: {
                                value: _vm.otpInput,
                                callback: function ($$v) {
                                  _vm.otpInput = $$v
                                },
                                expression: "otpInput",
                              },
                            }),
                            _c(
                              "base-button",
                              {
                                staticClass: "ml-2",
                                attrs: { color: "primary" },
                                on: { click: _vm.runVerification },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.tc("call_triage.auth.confirm")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm.currentUiState === _vm.UI_STATES.loading
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _c("base-spinner", {
                              attrs: { size: "xxl", loading: true },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }